import { RepeatIcon, SettingsIcon } from '@chakra-ui/icons';
import {
  Box,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  Text,
  Button,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import React, { FC, useState, useEffect } from 'react';

import { DEFAULT_SETTINGS } from '@app/constants/swap';
import { useSwapSettings } from '@app/hooks/swap/useSwapSettings';

interface Props {
  onRefresh?: () => void;
}

export const HeaderControls: FC<Props> = ({ onRefresh }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [slippage, setSlippage] = useState('');
  const [deadline, setDeadline] = useState('');

  const { settings, updateSettings } = useSwapSettings();

  useEffect(() => {
    if (settings) {
      setSlippage(settings.slippage.toString());
      setDeadline(settings.deadline.toString());
    }
  }, [settings]);

  return (
    <HStack w="100%" justify="flex-end" mb="4px" gap="16px">
      {onRefresh && (
        <IconButton
          aria-label="refresh"
          icon={
            <RepeatIcon
              w="18px"
              h="18px"
              color="neutral.300"
              transition="color 0.2 ease"
              _hover={{ color: 'white', cursor: 'pointer' }}
            />
          }
          onClick={onRefresh}
        />
      )}
      <IconButton
        aria-label="refresh"
        p="12px"
        border="1px solid"
        borderRadius="8px"
        borderColor="whiteOpacity.6"
        icon={
          <SettingsIcon
            w="18px"
            h="18px"
            color="neutral.300"
            transition="color 0.2 ease"
            _hover={{ color: 'white', cursor: 'pointer' }}
          />
        }
        onClick={onOpen}
      />
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        isCentered
        motionPreset="slideInBottom"
        size="xs"
      >
        <ModalOverlay />
        <ModalContent sx={{ maxWidth: ['100vw', '360px'] }}>
          <ModalHeader>
            <HStack justify="space-between" px={0} py={0} mb="16px">
              <Text fontSize="16px" color="black" fontWeight="500">
                Settings
              </Text>
              <ModalCloseButton
                onClick={() => {
                  onClose();
                }}
              />
            </HStack>
          </ModalHeader>
          <VStack w="100%">
            <Box mb="16px" w="100%">
              <Text
                textAlign="left"
                w="100%"
                color="black"
                fontSize="12px"
                lineHeight="18px"
                letterSpacing="-0.18px"
                mb="4px"
              >
                Slippage (%)
              </Text>
              <Input
                variant="filled"
                borderRadius="8px"
                bg="white"
                fontSize="xl"
                p="12px"
                border="none"
                color="black"
                gridArea="input"
                _focus={{
                  outline: 'none'
                }}
                _hover={{ bg: 'white' }}
                w="100%"
                type="number"
                step={0.1}
                value={slippage}
                onChange={e => setSlippage(e.target.value)}
                placeholder="0"
              />
            </Box>
            <Box mb="16px" w="100%">
              <Text
                textAlign="left"
                w="100%"
                color="black"
                fontSize="12px"
                lineHeight="18px"
                letterSpacing="-0.18px"
                mb="4px"
              >
                Deadline (mins)
              </Text>
              <Input
                variant="filled"
                borderRadius="8px"
                bg="white"
                fontSize="xl"
                p="12px"
                border="none"
                color="black"
                gridArea="input"
                _focus={{
                  outline: 'none'
                }}
                w="100%"
                _hover={{ bg: 'white' }}
                type="number"
                value={deadline}
                onChange={e => setDeadline(e.target.value)}
                placeholder="0"
              />
            </Box>
          </VStack>
          <ModalFooter justifyContent="space-between" gap="16px">
            <Button
              w="100%"
              bg="white"
              py="10px"
              px="20px"
              borderRadius="12px"
              onClick={async () => {
                setSlippage(DEFAULT_SETTINGS.slippage.toString());
                setDeadline(DEFAULT_SETTINGS.deadline.toString());
              }}
              color="neutral.400"
              _hover={{ background: 'sand', color: 'white' }}
            >
              <Text
                fontSize="16px"
                fontFamily="'Inter', sans-serif"
                fontWeight="500"
                lineHeight="20px"
              >
                Reset to defaults
              </Text>
            </Button>
            <Button
              w="100%"
              bg="white"
              py="10px"
              px="20px"
              borderRadius="12px"
              onClick={async () => {
                await updateSettings({
                  slippage: Number(slippage),
                  deadline: Number(deadline)
                });
                onClose();
              }}
              color="neutral.400"
              _hover={{ background: 'white', color: 'sand' }}
            >
              <Text
                fontSize="16px"
                fontFamily="'Inter', sans-serif"
                fontWeight="500"
                lineHeight="20px"
              >
                Save settings
              </Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </HStack>
  );
};
