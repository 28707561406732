import { NATIVE_TOKEN_ADDRESS } from '@thirdweb-dev/sdk';
import {
  CurrencyAmount,
  Token,
  NativeCurrency,
  Currency
} from '@uniswap/sdk-core';
import { Pair, Trade } from '@uniswap/v2-sdk';
import { useMemo } from 'react';

import { numberToBigNumberFixed } from '@app/helpers/format';
import { useAllPairs } from '@app/hooks/pair/useAllPairs';
import { Pair as KPair } from '@app/types/pool';
import { Token as KToken } from '@app/types/token';
import { getConfig, useConfig } from '@app/config';
import { getTokensWhitelist } from '@app/constants/tokensWhitelist';
import { useSupportedChain } from '@app/hooks/other/useSupportedChain';

export class ModeEther extends NativeCurrency {
  readonly address;
  readonly weth;
  readonly chainId;

  constructor(chainId: number, WETH: KToken) {
    super(chainId, WETH.decimals);
    this.address = WETH.contractAddress;
    this.weth = WETH;
    this.chainId = chainId;
  }

  get wrapped(): Token {
    return new Token(
      this.chainId,
      this.weth.contractAddress,
      this.weth.decimals,
      this.weth.symbol
    );
  }

  sortsBefore(other: Token): boolean {
    return this.address.toLowerCase() < other.address.toLowerCase();
  }

  equals(other: Currency): boolean {
    return other.isNative && other.chainId === this.chainId;
  }
}

function getToken(token: KToken, chainId: number) {
  const config = getConfig(chainId);

  const TOKENS_WHITELIST = getTokensWhitelist(config);

  return token.contractAddress === NATIVE_TOKEN_ADDRESS
    ? new ModeEther(
        chainId,
        TOKENS_WHITELIST.find(item => item.symbol === 'WETH')!
      )
    : new Token(chainId, token.contractAddress, +token.decimals, token.symbol);
}

function getCurrencyAmount(token: KToken, amount: string, chainId: number) {
  const config = getConfig(chainId);
  const TOKENS_WHITELIST = getTokensWhitelist(config);
  const _token =
    token.contractAddress === NATIVE_TOKEN_ADDRESS
      ? new ModeEther(
          chainId,
          TOKENS_WHITELIST.find(item => item.symbol === 'WETH')!
        )
      : new Token(
          chainId,
          token.contractAddress,
          +token.decimals,
          token.symbol
        );

  return CurrencyAmount.fromRawAmount(
    _token,
    numberToBigNumberFixed(+amount, token.decimals).toString()
  );
}

function gcd(a: number, b: number): number {
  return b ? gcd(b, a % b) : a;
}

export function decimalToFraction(_decimal: number) {
  if (_decimal % 1 == 0) {
    return {
      top: _decimal,
      bottom: 1,
      display: _decimal + ':' + 1
    };
  } else {
    let top: string | number = _decimal.toString().replace(/\d+[.]/, '');
    let bottom = Math.pow(10, top.length);

    if (_decimal > 1) {
      top = +top + Math.floor(_decimal) * bottom;
    }

    let x = gcd(+top, bottom);

    return {
      top: +top / x,
      bottom: bottom / x,
      display: +top / x + ':' + bottom / x
    };
  }
}

function getPair(pair: KPair, chainId: number) {
  const { token0, token1, reserve0, reserve1 } = pair;
  const tokenA = new Token(chainId, token0.id, +token0.decimals, token0.symbol);

  const currencyAmountA = CurrencyAmount.fromRawAmount(
    tokenA,
    numberToBigNumberFixed(+reserve0, +token0.decimals).toString()
  );

  const tokenB = new Token(chainId, token1.id, +token1.decimals, token1.symbol);

  const currencyAmountB = CurrencyAmount.fromRawAmount(
    tokenB,
    numberToBigNumberFixed(+reserve1, +token1.decimals).toString()
  );

  return new Pair(currencyAmountA, currencyAmountB);
}

export function useTrade(
  from: { token: KToken | null; amount: string },
  to: { token: KToken | null; amount: string }
) {
  const { data: pairs, refetch } = useAllPairs();
  const config = useConfig();
  const chainId = useSupportedChain()?.chainId;

  const trades = useMemo(() => {
    if (!pairs?.length || !from.token || !to.token || !chainId) {
      return;
    }

    if (
      from.token.contractAddress === config?.CONTRACTS.WETH &&
      to.token.contractAddress === NATIVE_TOKEN_ADDRESS
    ) {
      return;
    }

    if (
      to.token.contractAddress === config?.CONTRACTS.WETH &&
      from.token.contractAddress === NATIVE_TOKEN_ADDRESS
    ) {
      return;
    }

    const _pairs = pairs.map(pair => getPair(pair, chainId));

    const input = from.token;
    const output = to.token;

    if (!input || !output) {
      return;
    }

    return Trade.bestTradeExactIn(
      _pairs,
      getCurrencyAmount(input, from.amount, chainId),
      getToken(output, chainId)
    );
  }, [
    pairs,
    from.token,
    from.amount,
    to.token,
    chainId,
    config?.CONTRACTS.WETH
  ]);

  return {
    trades,
    refetch
  };
}
