import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { NATIVE_TOKEN_ADDRESS } from '@thirdweb-dev/sdk';
import { request, gql } from 'graphql-request';

import { QueryKeys } from 'src/constants/queryKeys';

import { PairToken } from '@app/types/pool';
import { useConfig } from '@app/config';

type QueryParams = {
  id?: string;
};

export const fetcher = (baseUrl: string, params: QueryParams) => {
  return request<{ token: PairToken }>(
    `${baseUrl}`,
    gql`
      query {
        token(
          id: "${params.id?.toLowerCase()}"
        )
         {
          id
            totalSupply
            symbol
            name
            decimals
            tradeVolumeUSD
            tradeVolume
            totalLiquidity
            txCount
            derivedETH
        }
      }
    `,
    params
  );
};

export const useTokenDetails = (
  params: QueryParams = {},
  options: UseQueryOptions<PairToken> = {}
) => {
  const config = useConfig();

  return useQuery<PairToken>(
    [QueryKeys.TOKEN_DETAILS, { params }],
    async () => {
      const response = await fetcher(config?.URLS.subgraphUrl as string, {
        ...params
      });

      return response.token;
    },
    {
      enabled: !!params.id && params.id !== NATIVE_TOKEN_ADDRESS,
      staleTime: 60000,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
