import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { request, gql } from 'graphql-request';

import { QueryKeys } from 'src/constants/queryKeys';

import { Pair } from '@app/types/pool';
import { getConfig } from '@app/config';
import { useSupportedChain } from '@app/hooks/other/useSupportedChain';

const fetcher = (chainId: number | undefined) => {
  const config = getConfig(chainId);

  return request<{ pairs: Pair[] }>(
    `${config?.URLS.subgraphUrl}`,
    gql`
      query {
        pairs {
          id
          token0 {
            id
            totalSupply
            symbol
            name
            decimals
            tradeVolumeUSD
            tradeVolume
            totalLiquidity
            txCount
            derivedETH
          }
          token1 {
            id
            totalSupply
            symbol
            name
            decimals
            tradeVolumeUSD
            tradeVolume
            totalLiquidity
            txCount
            derivedETH
          }
          reserve0
          reserve1
          reserveETH
          reserveUSD
          token0Price
          token1Price
          volumeUSD
        }
      }
    `
  );
};

export const useAllPairs = (options: UseQueryOptions<Pair[]> = {}) => {
  const chainId = useSupportedChain()?.chainId;

  return useQuery<Pair[]>(
    [QueryKeys.ALL_PAIRS, chainId],
    async () => {
      const response = await fetcher(chainId);

      return response.pairs;
    },
    {
      staleTime: 60000,
      ...options
    }
  );
};
