import { Box, Spinner, Text, Center } from '@chakra-ui/react';
import React, { FC } from 'react';

import { useTokenBalance } from '@app/hooks/swap/useTokenBalance';
import fromExponential from 'from-exponential';

interface Props {
  address: string;
}

export const TokenBalanceInfo: FC<Props> = ({ address }) => {
  const { data: selectedTokenBalance, isLoading } = useTokenBalance(address);

  return (
    <Box>
      {isLoading ? (
        <Center height="12px" width="12px">
          <Spinner width="9px" height="9px" color="neutral.400" />
        </Center>
      ) : (
        <Text
          color="neutral.200"
          fontSize="12px"
          lineHeight="12px"
          letterSpacing="-0.18px"
        >
          {fromExponential(Number(selectedTokenBalance?.displayValue))}{' '}
          {selectedTokenBalance?.symbol}
        </Text>
      )}
    </Box>
  );
};
