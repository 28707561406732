import { SmartContract, useContract } from '@thirdweb-dev/react';
import { TradeType } from '@uniswap/sdk-core';
import { Token as UNSToken } from '@uniswap/sdk-core/dist/entities/token';
import { Trade } from '@uniswap/v2-sdk';
import fromExponential from 'from-exponential';
import { useEffect } from 'react';
import { useAsyncFn } from 'react-use';

import routerAbi from 'abis/router.json';

import { isEthWethSwap } from '@app/hooks/swap/helpers';
import { Token } from '@app/types/token';
import { ModeEther } from '@app/hooks/swap/useTrade';
import { USTrade } from '@app/types/trade';
import {
  bigNumberToDecimal,
  numberToBigNumberFixed
} from '@app/helpers/format';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import { BaseContract } from 'ethers';
import { useConfig } from '@app/config';
import { ApplicationConfig } from '@app/config/types';

export function getCurrentTrade(
  selectedTrade:
    | Trade<UNSToken | ModeEther, UNSToken | ModeEther, TradeType.EXACT_INPUT>
    | undefined,
  trades:
    | Trade<UNSToken | ModeEther, UNSToken | ModeEther, TradeType.EXACT_INPUT>[]
    | undefined
) {
  if (selectedTrade) {
    return selectedTrade;
  }

  if (!trades) {
    return undefined;
  }

  let current:
    | Trade<UNSToken | ModeEther, UNSToken | ModeEther, TradeType.EXACT_INPUT>
    | undefined;

  trades.forEach(trade => {
    if (!current) {
      current = trade;
    }

    if (+current.priceImpact.toFixed() > +trade.priceImpact.toFixed()) {
      current = trade;
    }
  });

  return current;
}

// Deprecated
export function useTokensRate(
  from: Token,
  to: Token,
  amount: string,
  isSwapAvailable: boolean,
  trades: USTrade[] | undefined,
  selectedTrade: USTrade | undefined
) {
  const config = useConfig();
  const { contract } = useContract(config?.CONTRACTS.ROUTER, routerAbi);

  const [{ value: data, loading: isLoading }, fetchRates] =
    useAsyncFn(async () => {
      if (!isSwapAvailable || !contract || +amount === 0) {
        return;
      }

      if (isEthWethSwap(from, to, config)) {
        return {
          rate: '1',
          amountOut: amount
        };
      }

      const d = getCurrentTrade(selectedTrade, trades);

      const amountFromContract = await contract.call('getAmountsOut', [
        numberToBigNumberFixed(+amount, from.decimals),
        d?.route.path.map(item => item.address) // [from.contractAddress, to.contractAddress]
      ]);

      const lastAmount = amountFromContract[amountFromContract.length - 1];

      const amountOut = lastAmount
        ? bigNumberToDecimal(lastAmount, to.decimals).toString()
        : undefined;

      const rate = amountOut ? +amountOut / +amount : undefined;

      if (rate === undefined || isNaN(rate)) {
        return {
          rate: undefined,
          amountOut
        };
      }

      return {
        rate: fromExponential(rate.toString()),
        amountOut
      };
    }, [
      isSwapAvailable,
      contract,
      from,
      to,
      amount,
      selectedTrade,
      trades,
      config
    ]);

  useEffect(() => {
    fetchRates();
  }, [fetchRates]);

  return {
    swapOut: data?.amountOut
      ? fromExponential(data.amountOut)
      : data?.amountOut,
    rate: data?.rate,
    isLoading,
    fetchRates
  };
}

type TokensRateParams = {
  from: Token | undefined;
  to: Token | undefined;
  amount: string;
  isSwapAvailable: boolean;
  trades: USTrade[] | undefined;
  selectedTrade: USTrade | undefined;
};

type TokensRateParamsQueryParams = Omit<
  TokensRateParams,
  'trades' | 'selectedTrade'
> & {
  path: string[] | undefined;
};

type TokensRate = {
  swapOut: string | undefined;
  rate: string | undefined;
};

async function fetcher(
  params: TokensRateParamsQueryParams,
  contract: SmartContract<BaseContract> | undefined,
  config: ApplicationConfig | undefined
) {
  const { isSwapAvailable, amount, from, to, path } = params;

  if (!isSwapAvailable || !contract || +amount === 0 || !from || !to) {
    return;
  }

  if (isEthWethSwap(from, to, config)) {
    return {
      rate: '1',
      amountOut: amount
    };
  }

  if (!path) {
    return;
  }

  const amountFromContract = await contract.call('getAmountsOut', [
    numberToBigNumberFixed(+amount, from.decimals),
    path
  ]);

  const lastAmount = amountFromContract[amountFromContract.length - 1];

  const amountOut = lastAmount
    ? bigNumberToDecimal(lastAmount, to.decimals).toString()
    : undefined;

  const rate = amountOut ? +amountOut / +amount : undefined;

  if (rate === undefined || isNaN(rate)) {
    return {
      rate: undefined,
      amountOut
    };
  }

  return {
    rate: fromExponential(rate.toString()),
    amountOut
  };
}

export const useTokensRateV2 = (
  params: TokensRateParams,
  options: UseQueryOptions<TokensRate | undefined> = {}
) => {
  const config = useConfig();
  const { contract } = useContract(config?.CONTRACTS.ROUTER, routerAbi);

  const d = getCurrentTrade(params.selectedTrade, params.trades);
  const path = d?.route.path.map(item => item.address);

  const queryParams = {
    from: params.from,
    to: params.to,
    amount: params.amount,
    isSwapAvailable: params.isSwapAvailable,
    path
  };

  return useQuery<TokensRate | undefined>(
    [QueryKeys.TOKENS_RATE, queryParams],
    async () => {
      const data = await fetcher(queryParams, contract, config);

      return {
        swapOut: data?.amountOut
          ? fromExponential(data.amountOut)
          : data?.amountOut,
        rate: data?.rate
      };
    },
    {
      enabled: true,
      // staleTime: 30000,
      refetchInterval: 10000,
      ...options
    }
  );
};
