import { useAddress, useContract } from '@thirdweb-dev/react';
import { NATIVE_TOKEN_ADDRESS } from '@thirdweb-dev/sdk';
import { useEffect } from 'react';
import { useAsyncFn } from 'react-use';

import {
  bigNumberToDecimal,
  numberToBigNumberFixed
} from '@app/helpers/format';
import { Token } from '@app/types/token';

import erc20 from '../../../abis/erc20.json';
import { useConfig } from '@app/config';

type TokenItem = {
  token: Token | null;
  amount: string | null;
};

export function useTokenApprove(item: TokenItem, _spender?: string) {
  const address = useAddress();

  const config = useConfig();

  const spender = _spender ?? config?.CONTRACTS.ROUTER;

  const contractAddress =
    item.token?.contractAddress === NATIVE_TOKEN_ADDRESS
      ? config?.CONTRACTS.WETH
      : item.token?.contractAddress;

  const { contract } = useContract(contractAddress, erc20);

  const [{ value, loading: isFetchingAllowance }, getAllowance] =
    useAsyncFn(async () => {
      if (
        !contract ||
        !contractAddress ||
        !item.amount ||
        !address ||
        !spender ||
        !item.token
      ) {
        return;
      }

      try {
        const res = await contract.call('allowance', [address, spender]);

        return bigNumberToDecimal(res, item.token.decimals);
      } catch (e) {
        // do nothing
        // console.log(e);
      }
    }, [contract, address, contractAddress, item.amount, spender]);

  const [{ loading }, approve] = useAsyncFn(async () => {
    if (
      value === undefined ||
      Number(item.amount) <= value ||
      !item.token ||
      !contractAddress ||
      !contract
    ) {
      return;
    }

    const valueToApprove = Number(item.amount);

    return contract?.call('approve', [
      spender ?? '',
      numberToBigNumberFixed(valueToApprove, item.token.decimals)
    ]);
  }, [contract, contractAddress, item.amount, item.token, value, spender]);

  useEffect(() => {
    getAllowance();
  }, [getAllowance]);

  return {
    requiresApproval:
      item.token?.contractAddress !== NATIVE_TOKEN_ADDRESS &&
      !isFetchingAllowance &&
      value !== undefined &&
      Number(item.amount) > value,
    approve,
    isApproveInProgress: loading,
    getAllowance
  };
}
