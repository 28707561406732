import { useContract, useContractRead } from '@thirdweb-dev/react';
import { NATIVE_TOKEN_ADDRESS } from '@thirdweb-dev/sdk';

import factoryAbi from 'abis/factory.json';

import { isEthWethSwap } from '@app/hooks/swap/helpers';
import { Token } from '@app/types/token';
import { USTrade } from '@app/types/trade';
import { useConfig } from '@app/config';

export function useSwapAvailablity(
  from: Token,
  to: Token,
  trades: USTrade[] | undefined
): {
  isLoading: boolean;
  pairAddress: string | null;
  isSwapAvailable: boolean;
} {
  const config = useConfig();
  const { contract } = useContract(config?.CONTRACTS.FACTORY, factoryAbi);

  const { data, isLoading } = useContractRead(contract, 'getPair', [
    from.contractAddress === NATIVE_TOKEN_ADDRESS
      ? config?.CONTRACTS.WETH
      : from.contractAddress,
    to.contractAddress === NATIVE_TOKEN_ADDRESS
      ? config?.CONTRACTS.WETH
      : to.contractAddress
  ]);

  return {
    isLoading,
    pairAddress:
      data && data !== '0x0000000000000000000000000000000000000000'
        ? data
        : null,
    isSwapAvailable:
      isEthWethSwap(from, to, config) ||
      ((trades && trades.length > 0) ?? false)
  };
}
